/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { NavLink } from "react-router-dom";
import Button from "./plugins/Button";
import { IntlProvider } from "react-intl";
import Converter from "../data/Language/Converter";

export default function Footer({ locale, lang }) {
  return (
    <IntlProvider locale={locale} messages={lang}>
      <div className="footer">
        <div className="footer_bg">
          <div className="container py-3 h-100">
            <div className="line"></div>
            <div className="row">
              <div className="col-md-3 my-3 col-6 d-flex flex-column justify-content-start">
                <div className="logo-wrapper">
                  <div className="logo">
                    <span className="logo_item">T</span>
                    <span className="logo_item_center">T</span>
                    <span className="logo_item">S</span>
                  </div>
                  <div className="logo_bottom text-white">
                    Transoxania Technology Solutions
                  </div>
                </div>
                <div className="social d-flex">
                  <a
                    target="_blank"
                    href="https://web.telegram.org/z/#-1852054386"
                  >
                    <i className="bi bi-telegram"></i>
                  </a>
                  <a href="#/">
                    <i className="bi bi-linkedin"></i>
                  </a>
                  <a href="#/">
                    <i className="bi bi-facebook"></i>
                  </a>
                </div>
              </div>
              <div className="col-md-3 my-3 col-6 d-flex flex-column justify-content-start text-start">
                <i className="bi bi-building-fill"></i>
                <span className="upp">{Converter("Company")}</span>
                <h6>+998 99 895 90 90</h6>
              </div>
              <div className="col-md-3 my-3 col-6 d-flex flex-column justify-content-start text-start">
                <i className="bi bi-globe2"></i>
                <span className="upp">{Converter("get_started")}</span>
                <div className="links d-grid text-start">
                  <NavLink to="/">{Converter("Home")}</NavLink>
                  <NavLink to="/offering">{Converter("Offerings")}</NavLink>
                  <NavLink to="/project-portfolio">
                    {Converter("Project Portfolio")}
                  </NavLink>
                  <NavLink to="/contact">{Converter("Contact")}</NavLink>
                </div>
              </div>
              <div className="col-md-3 my-3 col-6 d-flex flex-column justify-content-start text-start">
                <i className="bi bi-geo-alt-fill"></i>
                <span className="upp">{Converter("Address")}</span>
                <p>
                  {Converter("TTS")} <br />
                  {Converter("full_address_company")}
                </p>
                <br />

                <NavLink to="contact">
                  <Button width="132px" height="60px" type="light">
                    {Converter("Contact")}
                  </Button>
                </NavLink>
              </div>
            </div>
            <div className="line"></div>
            <div className="d-flex align-items-center justify-content-center">
              <span>
                © {new Date().getFullYear()} {Converter("TTS")}{" "}
                {Converter("all_reserved")}
              </span>
            </div>
          </div>
        </div>
      </div>
    </IntlProvider>
  );
}
