import styled from "styled-components";

const getType = ({ type }) => {
  switch (type) {
    case "dark":
      return {
        background: "#000000",
        border: "1px solid #FFFFFF",
        color: "white",
      };
    case "light":
      return {
        background: "#FFFFFF",
        border: "1px solid #E6E9EC",
        color: "#0D263B",
      };
    case "primary":
      return {
        background: "#1d727b",
        border: "none",
        color: "white",
      };
      case "success":
      return {
        background: "#e9c912",
        border: "none",
        color: "white",
      };

    default:
      return {
        background: "#0061DF",
        border: "none",
        color: "white",
      };
  }
};

const getWidth = ({ width }) => {
  if (!width) return "230px";
  else if (`${width}`.includes("%")) return "100%";
  else return `${width}px`;
};

const Container = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  min-width: 120px;
  font-weight: 600;
  text-transform: ${({ capitalize }) => (capitalize ? `${capitalize}` : "uppercase")};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : "17px")};
  height: ${({ height }) => (height ? `${height}px` : "47px")};
  width: ${getWidth};
  opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  ${getType}
  :active {
    opacity: 0.7;
  }
`;

export { Container };