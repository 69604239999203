import React, { useEffect } from "react";
import { IntlProvider } from "react-intl";
import { Link } from "react-router-dom";
import Converter from "../../data/Language/Converter";

const Home = ({ locale, lang }) => {
  useEffect(() => {
    window.scrollTo(document.body.scrollHeight, 0);
  }, []);

  return (
    <IntlProvider locale={locale} messages={lang}>
      <div className="home">
        <section id="hero" className="d-flex align-items-center">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <h1>{Converter("Better_Solutions")}</h1>
                <h2>{Converter("main_page_text")}</h2>
                <div className="d-flex justify-content-center justify-content-lg-start">
                  <Link className="main_btn" to="/contact">
                    {Converter("Contact")}
                  </Link>
                </div>
              </div>
              <div
                className="col-lg-6 order-1 order-lg-2 hero-img"
                data-aos="zoom-in"
                data-aos-delay="200"
              >
                <img
                  src="./assets/img/hero-img.png"
                  className="img-fluid animated"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </IntlProvider>
  );
};

export default Home;
