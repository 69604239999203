import React, { useEffect } from "react";
import { IntlProvider } from "react-intl";
import Converter from "../../data/Language/Converter";

export default function ProjectPortfolio({ locale, lang }) {
  useEffect(() => {
    window.scrollTo(document.body.scrollHeight, 0);
  }, []);

  return (
    <IntlProvider locale={locale} messages={lang}>
      <div className="portfolio-project">
        <div className="portfolio b_g text-start">
          <div className="portfoli_bg">
            <div
              className="container"
              style={{ height: "100%", display: "flex", alignItems: "center" }}
            >
              <div
                className="text-left d-grid"
                style={{ color: "#fff", width: "70%" }}
              >
                <h1>{Converter("Project_Program")}</h1>
                <h3>{Converter("Delivering Results")}</h3>
              </div>
            </div>
          </div>
        </div>

        <div className="services py-5">
          <div className="container">
            <h1 id="titl">{Converter("Services")}</h1>
            <h3
              className="my-3"
              style={{ fontSize: "22pt", color: "#444", fontWeight: "500" }}
            >
              {Converter("Were dedicated")}
            </h3>
            <div className="row">
              <div className="col-md-3 pr__description">
                <img src="/assets/img/rebrand/assess-p3.jpg" alt="" />
                <div>
                  <h4>{Converter("Portfolio management")}</h4>
                  <p>{Converter("Portfolio man")}</p>
                </div>
              </div>
              <div className="col-md-3 pr__description">
                <img src="/assets/img/rebrand/transform-p3.jpg" alt="" />
                <div>
                  <h4>{Converter("Project Manageme")}</h4>
                  <p>{Converter("Project manag")}</p>
                </div>
              </div>
              <div className="col-md-3 pr__description">
                <img src="/assets/img/rebrand/train-coach.jpg" alt="" />
                <div>
                  <h4>{Converter("Resource management")}</h4>
                  <p>{Converter("Resource mana")}</p>
                </div>
              </div>
              <div className="col-md-3 pr__description">
                <img src="/assets/img/rebrand/operate-P3.jpg" alt="" />
                <div>
                  <h4>{Converter("Agile training")}</h4>
                  <p>{Converter("Agile trainin")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </IntlProvider>
  );
}
