export const assurance = {
  main: {
    src: "/assets/img/quality-assurance.svg",
    title: `Quality Assurance `,
    desc: `Make sure your product is fully ready for the market. Our QA and software testing services will help you reach an established product quality threshold and deliver a supreme user experience to your customers. For over a decade, our manual QA experts have been using their personal expertise and the latest testing practices and technologies to ensure stable product performance in all environments and under all workloads. `,
  },
  content: [
    {
      title: `Functional testing `,
      desc: `Our testers excel at validating your software against functional specifications and requirements. During testing, experienced QA engineers directly interact with your product to make sure all features behave as intended. `,
    },
    {
      title: `Regression testing `,
      desc: `Roll out new features and product updates with the least disruption. Our QA experts perform rigorous testing on new updates and ensure code changes have no side effects on existing functionalities. `,
    },
    {
      title: `Usability testing `,
      desc: `Our testers think like your end users and know the benchmarks of a user-friendly digital product. We identify confusing designs, optimize user experience, and test graphic interfaces from a user’s perspective and with human intelligence. `,
    },
    {
      title: `Performance testing `,
      desc: `Our performance testing experts apply time-tested tools to measure software reliability at load. We help your application deliver value at peak hours and ensure scalability with no crashes or long response times. `,
    },
    {
      title: `Localization testing `,
      desc: `Expand your market reach to any location and maintain high product quality across regions. Our QA team validates your software behavior for a given locale to better adapt your product to the cultural and linguistic specifics of that location. `,
    },
    {
      title: `Compatibility testing `,
      desc: `Ensure complete customer satisfaction across platforms, devices, and operating systems. Our company provides full-cycle compatibility testing services to cover each environment and validate the product integrity on all platforms. `,
    },
    {
      title: `Acceptance testing`,
      desc: `Our company helps you test the final product version for user and business compliance so you can release software with confidence. Following your unique specifications, the QA team checks the app against acceptance criteria to make sure your product is ready to hit the market. `,
    },
    {
      title: `Integration testing `,
      desc: `We make sure all your software elements integrate seamlessly with each other and operate well both as discrete modules and as a combined entity. Our engineers track down and resolve functional, performance, and reliability issues in software head-on. `,
    },
  ],
};
