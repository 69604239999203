import React from "react";
import Converter from "../data/Language/Converter";
import { IntlProvider } from "react-intl";
import { Link, NavLink } from "react-router-dom";

export default function Header({ changeLang, locale, lang }) {
  return (
    <IntlProvider locale={locale} messages={lang}>
      <div className="header">
        <div className="head_top">
          <div className="container">
            <div className="text-start">
              {/* <i className="fas fa-phone-alt"></i> +998 99 768 76 38 */}
            </div>
          </div>
        </div>

        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid container">
            <div className="logo-wrapper">
              <div className="logo">
                <span className="logo_item">T</span>
                <span className="logo_item_center">T</span>
                <span className="logo_item">S</span>
              </div>
              <div className="logo_bottom">
                Transoxania Technology Solutions
              </div>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav w-100 justify-content-between">
                <div className="nav_flex">
                  <li className="nav-item">
                    <NavLink
                      to="/"
                      id="a"
                      className="nav-link a"
                      aria-current="page"
                    >
                      {Converter("Home")}
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/offering" id="a" className="nav-link a">
                      {Converter("Offerings")}
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/project-portfolio"
                      id="a"
                      className="nav-link a"
                    >
                      {Converter("Project Portfolio")}
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/contact" id="a" className="nav-link a">
                      {Converter("Contact")}
                    </NavLink>
                  </li>
                </div>
                <div className="select_translate">
                  <img
                    height="25px"
                    alt="#"
                    src="/assets/img/globe-svgrepo-com (1).svg"
                  />
                  <select
                    style={{ width: "70px" }}
                    className="form-select a ms-3"
                    onChange={(e) => changeLang(e.target.value)}
                    defaultValue={localStorage.getItem("language")}
                  >
                    <option value="uz">uz</option>
                    <option value="en">en</option>
                  </select>
                </div>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </IntlProvider>
  );
}
