export const development = {
  main: {
    src: "/assets/img/web-development.svg",
    title: `Web App Development `,
    desc: `Web app development services help you leverage the power of modern web frameworks to build competitive digital solutions that bring results. As a mature web development company, Transoxania Technology Solutions (TTS) delivers scalable, dynamic, and feature-rich web applications aligned with your business objectives. `,
  },
  content: [
    {
      title: `Custom Web App Development`,
      desc: `Off-the-shelf solutions cannot satisfy your business needs? Turn to our custom software development where each element of a web application is attuned to your requirements. We create user-driven UI/UX for maximum usability, robust architecture for stable performance, and wrap it all into maintainable code for easy scalability. `,
    },
    {
      title: `Web Redesign and Modernization`,
      desc: `Rebuild your application to gain a competitive edge in the market. TTS assists companies in harnessing the potential of the latest technologies, trendy designs, and innovative architectures to deliver unmatched user experience and future-proof your company. `,
    },
    {
      title: `Tech Consulting `,
      desc: `Make the right technology decisions, minimize operational risks, and respond to market dynamics with our professional tech advisory. Our developers guide companies in adopting new digital channels with the right tech stack, architecture, data security approaches, and other essentials. `,
    },
    {
      title: `API Development and Integration`,
      desc: `Inject added value into your web app without hefty development costs. Our web developers augment web solutions with geolocation, analytics, payment processing, and other business-critical integrations that accelerate your app performance and respond to evolving user demands. `,
    },
  ],
};
