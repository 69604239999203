import React, { useEffect } from "react";
import { IntlProvider } from "react-intl";
import Button from "../plugins/Button";

export default function Background({ locale, lang }) {
  useEffect(() => {
    window.scrollTo(document.body.scrollHeight, 0);
  }, []);

  return (
    <IntlProvider locale={locale} messages={lang}>
      <div className="background">
        <div className="bg_protect container py-4">
          <div className="row py-2">
            <div className="col-sm-12 col-md-6">
              <h2 className="no-margin-top">Protect Your Organisation</h2>
              <p className="body">
                It is often difficult to effectively manage the risk of new
                hires without dedicated resources, expertise and supporting
                technology.{" "}
              </p>
              <p className="body">That&apos;s where we come in.</p>
              <p className="body">
                Our pre-employment background screening can help inform your
                hiring decisions without taking up valuable HR time.
              </p>
              <p className="body">
                We verify your candidate&apos;s details, experience and
                qualifications, then we present the results to you in a simple
                report.{" "}
              </p>
              <p className="body">
                Start mitigating your employment risk more efficiently today.{" "}
              </p>

              <div className="mt-4">
                <Button type="primary" width="155" height="49">
                  Start Now
                </Button>
              </div>
            </div>
            <div className="d-none d-md-inline col-md-6">
              <img
                className="img-fluid"
                src="/assets/img/rebrand/Image_Protect-Your-Business.jpg"
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="bg_grey py-5 border">
          <div className="container">
            <div className="row text-muted">
              <div className="col-md-4 text-center">
                <img
                  height="90px"
                  width="90px"
                  src="/assets/img/rebrand/Icon_quality-hire.svg"
                  alt="img"
                ></img>
                <h4 className="text-color-light-gray">
                  66% of EMEA employers say a leading benefit of conducting
                  background checks is improved regulatory compliance.
                </h4>
              </div>

              <div className="col-md-4 text-center">
                <div className="visible-xs margin-top-50"></div>
                <img
                  alt="img"
                  height="90px"
                  width="90px"
                  src="/assets/img/rebrand/icon_discrepency.svg"
                ></img>
                <h4 className="text-color-light-gray">
                  Over half of EMEA employers said that screening contributed to
                  a better quality of hires.{" "}
                </h4>
              </div>

              <div className="col-md-4 text-center">
                <div className="visible-xs margin-top-50"></div>
                <img
                  alt=""
                  height="90px"
                  width="90px"
                  src="/assets/img/rebrand/icon_discrepency-on-document.svg"
                ></img>
                <h4 className="text-color-light-gray">
                  53% of EMEA employers found discrepancies during their
                  candidates' employment verifications.
                </h4>
              </div>
              <div className="col-12 text-small text-end mt-2">
                Source: HireRight 2021 Global Benchmark Report
              </div>
            </div>
          </div>
        </div>

        <div className="bg_get">
          <div className="container py-4">
            <div className="row">
              <div className="col-sm-12">
                <h2 className="h2 my-3">Get it Right Every Time, Everywhere</h2>
              </div>
              <div className="col-md-10">
                <p>
                  We know how important it is to get the right people into your
                  business to maintain your high standards.
                </p>
                <p>
                  Background screening, if it&apos;s done right, can help make
                  this easier for you.{" "}
                </p>
                <p>
                  We want you to be confident in your candidates, and your
                  candidates to be confident in us.{" "}
                </p>
                <p>
                  That&apos;s why we use tested processes to provide accurate
                  data verifications.{" "}
                </p>
                <p>
                  That&apos;s why so many organisations trust HireRight as their
                  background screening provider.
                </p>
                <div className="mt-4 mb-3">
                  <Button
                    type="light"
                    width="180"
                    height="36"
                    capitalize="capitalize"
                  >
                    See Reasons Why
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg_see container my-5 text-center ">
          <h2 className="fw-bold mx-md-5">
            See why approximately 40,000 organisations trust HireRight as their
            background screening provider
          </h2>
          <div className="mt-4 mb-3 d-flex justify-content-center">
            <Button type="primary">Why Choose HireRight</Button>
          </div>
        </div>
      </div>
    </IntlProvider>
  );
}
