/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useEffect, useRef, useState } from "react";
import { IntlProvider } from "react-intl";
import Converter from "../../data/Language/Converter";
import ReCAPTCHA from "react-google-recaptcha";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useForm } from "react-hook-form";
import { CircularProgress } from "@mui/material";

export default function ContactSales({ locale, lang }) {
  const [spinner, setSpinner] = useState(false);
  const [captcha, setCaptcha] = useState(false);
  // const [lang, setLang] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    window.scrollTo(document.body.scrollHeight, 0);
  }, []);

  useEffect(() => {}, [sessionStorage.getItem("language")]);

  const Submit = (data) => {
    if (!captcha) {
      toast.warning("Check the reCaptcha!");
      return;
    }
    setSpinner(true);

    fetch("https://tts-cms-apis.herokuapp.com/send-email", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => {
        toast.success("Successfully sended!");
        setSpinner(false);
        reset();
      })
      .catch((error) => {
        toast.error("An Error occurred!");
        setSpinner(false);
      });
  };

  const reCaptcha = () => {
    setCaptcha(true);
  };

  return (
    <IntlProvider locale={locale} messages={lang}>
      <ToastContainer />
      <div className="container py-5">
        <div className="row justify-content-center px-sm-3 mb-3">
          <div className="col-md-10 col-12">
            <div className="form-container">
              <form
                onSubmit={handleSubmit(Submit)}
                className="container py-2 example row g-3"
              >
                <h4 className="text-align">{Converter("Contact Us")}</h4>
                <div className="col-md-6">
                  <input
                    type="text"
                    placeholder={lang.First_Name}
                    className={errors.firstName ? "error" : {}}
                    {...register("firstName", {
                      required: true,
                      pattern: /^[A-Za-z' ]*$/,
                    })}
                  />
                </div>
                <div className="col-md-6">
                  <input
                    type="text"
                    placeholder={lang.Last_Name}
                    className={errors.lastName ? "error" : {}}
                    {...register("lastName", {
                      required: true,
                      pattern: /^[A-Za-z' ]*$/,
                    })}
                  />
                </div>
                <div className="col-md-6">
                  <input
                    type="text"
                    placeholder={lang.Job_Title}
                    className={errors.job ? "error" : {}}
                    {...register("job", {
                      required: true,
                      pattern: /^[A-Za-z0-9' ]*$/,
                    })}
                  />
                </div>
                <div className="col-md-6">
                  <input
                    type="tel"
                    placeholder={lang.Phone_Number}
                    className={errors.phone ? "error" : {}}
                    {...register("phone", {
                      required: true,
                      pattern:
                        /^998([- ])?(90|91|93|94|95|98|99|33|97|71)([- ])?(\d{3})([- ])?(\d{2})([- ])?(\d{2})$/,
                    })}
                  />
                </div>
                <div className="col-md-6">
                  <input
                    type="tel"
                    placeholder={lang.City}
                    className={errors.city ? "error" : {}}
                    {...register("city", {
                      required: true,
                      pattern: /^[A-Za-z0-9' ]*$/,
                    })}
                  />
                </div>
                <div className="col-md-6">
                  <select
                    className={errors.country ? "error" : {}}
                    {...register("country", {
                      required: true,
                      pattern: /^[A-Za-z0-9' ]*$/,
                    })}
                  >
                    <option value="">{lang.Country}</option>
                    <option value="Uzb">{lang.Uzb}</option>
                    <option value="USA">{lang.USA}</option>
                    <option value="Ru">{lang.Ru}</option>
                  </select>
                </div>
                <div className="col-md-6">
                  <input
                    type="text"
                    placeholder={lang.Email}
                    className={errors.email ? "error" : {}}
                    {...register("email", {
                      required: true,
                      pattern: /^\S+@\S+$/i,
                    })}
                  />
                </div>
                <div className="col-md-6">
                  <input
                    type="text"
                    placeholder={lang.Company_Name}
                    className={errors.company ? "error" : {}}
                    {...register("company", {
                      required: true,
                      pattern: /^[A-Za-z0-9' ]*$/,
                    })}
                  />
                </div>
                <div className="col-md-12">
                  <textarea
                    rows="2"
                    placeholder={lang.comment}
                    className={errors.comment ? "error w-100" : "w-100"}
                    {...register("comment", { pattern: /^[A-Za-z0-9' ]*$/ })}
                  ></textarea>
                </div>

                <div className="col-md-12">
                  <ReCAPTCHA
                    onChange={reCaptcha}
                    sitekey="6LcU3ackAAAAAHTlrVLj7juoIGEzBQ2h5l7QxQpG"
                  />
                  <p className="text-right float-end my-1 p-0">
                    <button
                      className="btn btn_style"
                      type="submit"
                      disabled={spinner}
                    >
                      {spinner && (
                        <CircularProgress
                          style={{ color: "#fff" }}
                          size="0.93rem"
                        />
                      )}{" "}
                      {Converter("Send Now")}
                    </button>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </IntlProvider>
  );
}
