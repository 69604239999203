export const support = {
  main: {
    src: "/assets/img/web-development.svg",
    title: `TTS Technical Support `,
    desc: `We want you to use our products efficiently and effectively. That’s why we offer hundreds of learning opportunities each year across a variety of topics, user levels, and formats. You’ll also learn from your peers. TTS’s client community is one of the most active and engaged in the industry, demonstrating consistently high levels of client involvement and satisfaction year after year. `,
  },
  content: [
    {
      title: `TTS Technical Support`,
      desc: `Off-the-shelf solutions cannot satisfy your business needs? Turn to our custom software development where each element of a web application is attuned to your requirements. We create user-driven UI/UX for maximum usability, robust architecture for stable performance, and wrap it all into maintainable code for easy scalability. `,
    },
    {
      title: `TTS Technical Support`,
      desc: `And we go above and beyond to make sure your products always work as they should. Unlike other vendors TTS’s support is unlimited. Your annual maintenance not only covers all product support, enhancement, and upgrades to the software, but allows Certified/Trained end-users to make an unlimited number of calls to the TTS’s Help Desk. `,
    }
  ],
};