import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./components/Pages/Home";
import Offering from "./components/Pages/Offering";
import Background from "./components/Pages/Background";
import Contact from "./components/Pages/Contact";

import Uzbek from "./translations/uz.json";
import English from "./translations/en.json";
import LanguageProvider from "./data/Language/LanguageProvider";
import ProjectPortfolio from "./components/Pages/ProjectPortfolio";

function App() {
  const [locale, setLocale] = useState("uz");
  const [lang, setLang] = useState(Uzbek);

  const changeLang = (getLang) => {
    setLocale(getLang);
    localStorage.setItem("language", getLang);
    switch (getLang) {
      case "uz":
        setLang(Uzbek);
        break;
      case "en":
        setLang(English);
        break;
      default:
        return "uz";
    }
  };

  useEffect(() => {
    localStorage.getItem("language")
      ? changeLang(localStorage.getItem("language"))
      : changeLang("uz");
  }, []);

  return (
    <div className="App">
      <LanguageProvider locale={locale} lang={lang}>
        <Header changeLang={changeLang} locale={locale} lang={lang} />
        <Routes>
          <Route
            exact
            path="/"
            element={<Home locale={locale} lang={lang} />}
          />
          <Route
            path="/contact"
            element={<Contact locale={locale} lang={lang} />}
          />
          <Route
            path="/offering"
            element={<Offering locale={locale} lang={lang} />}
          />
          <Route
            path="/background"
            element={<Background locale={locale} lang={lang} />}
          />
          <Route
            path="/project-portfolio"
            element={<ProjectPortfolio locale={locale} lang={lang} />}
          />
        </Routes>
        <Footer locale={locale} lang={lang} />
      </LanguageProvider>
    </div>
  );
}

export default App;
