/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import Slider from "react-slick";
import Converter from "../data/Language/Converter";

export default class PauseOnHover extends Component {
  render() {

    var settings = {
      dots: true,
      infinite: true,
      autoplay: true,
      pauseOnHover: true,
      autoplaySpeed: 2200,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    return (
      <div className="carousel">
        <Slider {...settings}>
          <div id='carousel_box'>
            <img src="/assets/img/financial1.jpg" className='img-fluid'/>
            <h3>{Converter("Finance and Insurance")}</h3>
          </div>
          <div id='carousel_box'>
            <img src="/assets/img/industrial.jpg" className='img-fluid'/>
            <h3>{Converter("Industrial")}</h3>
          </div>
          <div id='carousel_box'>
            <img src="/assets/img/rebrand/Finance-and-Insurance-Landing-Page-Header.jpg" className='img-fluid'/>
            <h3>{Converter("Commercial")}</h3>
          </div>
          <div id='carousel_box'>
            <img src="/assets/img/healthcare.png" className='img-fluid'/>
            <h3>{Converter("Healthcare")}</h3>
          </div>
          <div id='carousel_box'>
            <img src="/assets/img/retail.png" className='img-fluid'/>
            <h3>{Converter("Retail")}</h3>
          </div>
          <div id='carousel_box'>
            <img src="/assets/img/house.jpg" className='img-fluid'/>
            <h3>{Converter("Residential")}</h3>
          </div>
        </Slider>
      </div>
    );
  }
}