/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { development } from "../../data/development";
import { management } from "../../data/management";
import { assurance } from "../../data/assurance";
import { support } from "../../data/support";
import { IntlProvider } from "react-intl";
import Carousel from "../Carousel";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Converter from "../../data/Language/Converter";

const Offering = ({ locale, lang }) => {
  const arr = [management, development, assurance, support];
  const [array, setArray] = useState([]);

  useEffect(() => {
    window.scrollTo(document.body.scrollHeight, 0);
  }, []);

  return (
    <IntlProvider locale={locale} messages={lang}>
      <div className="offering">
        <div className="our b_g">
          <div className="off_bg">
            <div
              className="container d-flex align-items-center"
              style={{ height: "100%" }}
            >
              <div className="text-left d-grid" style={{ color: "#fff" }}>
                <h1>{Converter("Our Offerings")}</h1>
                <h3>{Converter("IT Support")}</h3>
              </div>
            </div>
          </div>
        </div>

        <div className="py-4">
          <div className="container">
            <h1 id="title">{Converter("What We Do")}</h1>
            <div className="row">
              {arr.map((el) => (
                <div
                  key={el.main.title}
                  className="col-xl-3 col-md-6 px-3 py-3 d-flex justify-content-center"
                >
                  <div className="cardd p-4">
                    <div>
                      <i className="fa-solid fa-screwdriver-wrench"></i>
                      <img
                        className="m-2"
                        width="30%"
                        src={el.main.src}
                        alt={el.main.title}
                      />
                      <h3 className="my-3">
                        {Converter(`${el.main.title.slice(0, 16)}`)}
                      </h3>
                      <p>{Converter(`${el.main.title.slice(0, 15)}`)}</p>
                    </div>
                    <button
                      onClick={(e) => setArray(el)}
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      className="btn"
                    >
                      {Converter("Read More")}
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="container py-5">
          <Carousel />
        </div>

        {/* <!-- Modal --> */}
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                {array.content?.map((elem) => (
                  <input key={elem.title} type="radio" name="position" />
                ))}
                <main id="carousel">
                  {array.content?.map((elem, j) => (
                    <div key={j} className="item p-4">
                      <h5 className="card-title mb-2">
                        {Converter(elem.title.slice(0, 16))}
                      </h5>
                      <p className="card-text">
                        {Converter(elem.desc.slice(0, 16))}
                      </p>
                    </div>
                  ))}
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    </IntlProvider>
  );
};

export default Offering;
