export const management = {
  main: {
    src: "/assets/img/project-management.svg",
    title: `Project Management`,
    desc: `Our project managers follow the best Agile practices delivering the projects. Such an approach helps us to adapt to changes quicker yet maintain the quality of the products we develop at its highest. 
        We deliver the most value by implementing iterative efforts and taking care of all the impediments appearing on the way.`,
  },
  content: [
    {
      title: `Agile products are built iteratively and incrementally`,
      desc: `Instead of planning, developing, and releasing the product all at once, small valuable increments are delivered. Our sprint-based approach allows us to deliver products that match your business objectives and your users’ needs.`,
    },
    {
      title: `Agile products cost less than traditional ones`,
      desc: `Firstly, we are constantly receiving feedback from you and are focusing on value. Secondly, at each sprint, the team reflects on product quality and progress, making the product more efficient. This helps us eliminate unnecessary steps and save your budget. `,
    },
    {
      title: `Agile works great when the projects involve a lot of uncertainty`,
      desc: `If you don’t have all your requirements well documented, it’s not a problem. We will help you to organize the Backlog to start. All you need is to provide constant feedback and prioritize the most important features. Such collaboration will help us continually improve the product together. `,
    },
    {
      title: `Agile products adapt to changes quickly`,
      desc: `The market is susceptible to changes; your product should resonate accordingly. The benefit of the Agile approach is that no matter what changes in requirements occur throughout the project’s lifecycle, they can be easily integrated into the flow thanks to the task prioritization and iterative approach. `,
    },
  ],
};
