import { Container } from "./style";
import React from 'react'

export const Button = ({children,  type, onClick, width, height, disabled, capitalize}) => {
  return (
    <Container disabled={disabled} onClick={onClick} type={type} width={width} height={height} capitalize={capitalize}>
      {children || "Generic Button"}
    </Container>
  );
};

export default Button







