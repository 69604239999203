import { IntlProvider } from "react-intl";

function LanguageProvider(props) {
  const { locale, lang } = props;
  return (
    <IntlProvider locale={locale} messages={lang}>
      <div className="text-center">{props.children}</div>
    </IntlProvider>
  );
}

export default LanguageProvider;